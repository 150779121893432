import React from "react"
import "../components/login.css"

const TermsAndConditions = () => {
  return (
    <div className="text-center">
      <p className="termsCondition">
        By signing up or logging in, you are agreeing to our fascinating &nbsp;
        <a href="https://swiftcloud.ai/terms" target="_blank">
          Terms of Use
        </a>{" "}
        &amp;{" "}
        <a href="https://swiftcloud.ai/privacy" target="_blank">
          Privacy Policy
        </a>
        .
      </p>
    </div>
  )
}

export default TermsAndConditions
