import * as React from "react"

import Login from "../components/login"
import Seo from "../components/seo"
import ReduxWrapper from "../redux/reduxWrapper"
import { GoogleOAuthProvider } from "@react-oauth/google"

const LoginPage = props => {
  const params = new URLSearchParams(props.location.search)
  return (
    <React.Fragment>
      <Seo title="Login" />
      {/*<GoogleOAuthProvider clientId={process.env.GATSBY_GOOGLE_CLIENT_ID}>*/}
      <Login location={props.location} switchUserEmail={params.get("switch_user")} />
      {/*</GoogleOAuthProvider>*/}
    </React.Fragment>
  )
}

const WrappedPage = props => <ReduxWrapper element={<LoginPage {...props} />} />
export default WrappedPage
